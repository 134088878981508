import React, { useRef, useState } from "react";
import { Col, Form, Row, Button } from "react-bootstrap";
import "./Contact.scss";
import { ImLocation2 } from "react-icons/im";
import { MdEmail, MdOutlineCall } from "react-icons/md";
import emailjs from "emailjs-com";
import { AiFillSkype } from "react-icons/ai";
import swal from "sweetalert";
const Contact = () => {
  const form = useRef();
  const [email, setEmail] = useState({
    to_name: "",
    from_name: "",
    from_subject: "",
    phone: "",
    message: "",
  });
  const handleChane = (e) => {
    const newMail = { ...email };
    newMail[e.target.name] = e.target.value;
    setEmail(newMail);
  };

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_yz1b0dj",
        "template_1cj6pvf",
        form.current,
        "K-qpvUFnuQFW7tLLb"
      )
      .then(
        () => {
          swal({
            title: "Good job!",
            text: "Your Email Send Successfully!",
            icon: "success",
            button: "OK",
          });
        },
        (error) => {
          swal({
            title: "Oh No!",
            text: error.text,
            icon: "warning",
            button: "OK",
          });
        }
      );

    document.getElementById("contactForm").reset();
  };
  return (
    <div className="bg-contact py-2">
      <div id="contact" className="contact-margin container">
        <div className="text-center mb-5">
          <h2 className="text-secondary mb-3">
            CONTACT US
          </h2>
        </div>
        <Row className="d-md-flex align-items-center">
          <Col md={5} className="p-md-0">
            <div className="d-flex text-contact align-items-center">
              <span className="rounded-circle">
                <ImLocation2 className="fs-1 text-contact" />
              </span>
              <div className="ms-3">
                <h4>Location</h4>
                <p>
                  Holding # GA-36 (Level-4), Shahjadpur, Gulshan <br />{" "}
                  Dhaka-1212, Bangladesh.
                </p>
              </div>
            </div>
            <div className="d-flex mt-2 text-contact align-items-center">
              <span>
                <MdEmail className="fs-1 text-contact" />
              </span>
              <div className="ms-3">
                <h4>Email</h4>
                <p>info@najjtechnologies.com</p>
              </div>
            </div>
            <div className="d-flex mt-2 text-contact align-items-center">
              <span>
                <MdOutlineCall className="fs-1 text-contact" />
              </span>
              <div className="ms-3">
                <h4>Call</h4>
                <p>+8801316686977</p>
              </div>
            </div>
            <div className="d-flex mt-2 text-contact align-items-center">
              <span>
                <AiFillSkype className="fs-1 text-contact" />
              </span>
              <div className="ms-3">
                <h4>Skype</h4>
                <p>live:.cid.ca169b96eeba46f4</p>
              </div>
            </div>
          </Col>
          <Col md={7} className="rounded text-contact">
            <Form onSubmit={sendEmail} ref={form} id="contactForm">
              <div className="d-md-flex">
                <Form.Group className="width me-md-3">
                  <Form.Control
                    type="text"
                    placeholder="Enter name"
                    name="to_name"
                    onChange={handleChane}
                    required
                  />
                </Form.Group>
                <Form.Group className="width mt-3 mt-md-0">
                  <Form.Control
                    type="email"
                    placeholder="name@example.com"
                    name="from_name"
                    onChange={handleChane}
                    required
                  />
                </Form.Group>
              </div>
              <div className="d-md-flex mt-2">
                <Form.Group className="width me-md-3">
                  <Form.Control
                    type="text"
                    placeholder="Subject"
                    name="from_subject"
                    onChange={handleChane}
                    required
                  />
                </Form.Group>
                <Form.Group className="width mt-3 mt-md-0">
                  <Form.Control
                    type="number"
                    placeholder="phone"
                    name="phone"
                    onChange={handleChane}
                    required
                  />
                </Form.Group>
              </div>
              <Form.Group className="mt-3">
                <Form.Control
                  as="textarea"
                  rows={7}
                  placeholder="Message..."
                  name="message"
                  onChange={handleChane}
                  required
                />
              </Form.Group>
              <div className="fw-bold mt-2 ">
                <Button type="submit" className="rounded send-btn border-0">
                  SEND MESSAGE
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Contact;
