import React from "react";
import "./Technology.scss";
import react from "../../image/tech/react.svg";
import php from "../../image/tech/php.svg";
import laravel from "../../image/tech/laravel.svg";
import mongodb from "../../image/tech/mongodb.svg";
import mysql from "../../image/tech/mysql.svg";
import nodejs from "../../image/tech/nodejs.svg";
import express from "../../image/tech/express.svg";

const Technology = () => {
  return (
    <div className="d-md-flex container contact-margin align-items-center">
      <div className="d-flex align-items-center justify-content-center mb-5 mb-md-0">
        <h2 className="text-secondary text-center">
          TECHNOLOGY <br className="d-none d-md-block" /> EXPERTS
        </h2>
      </div>
      <div className="technology d-none d-md-block" />
      <div className="ms-md-4 d-md-flex align-items-center justify-content-between w-100">
        <div className="d-flex align-items-center justify-content-center">
          <img className="react-logo" src={react} alt="react logo" />
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <img className="express-logo" src={express} alt="express logo" />
        </div>
        <div className="d-flex align-items-center justify-content-center pe-md-4">
          <img className="node-logo" src={nodejs} alt="nodejs logo" />
        </div>
        <div className="d-flex align-items-center justify-content-center mb-4 mb-md-0">
          <img className="laravel-logo" src={laravel} alt="laravel logo" />
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <img className="php-logo" src={php} alt="php logo" />
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <img className="mongodb-logo" src={mongodb} alt="mongodb logo" />
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <img className="mysql-logo" src={mysql} alt="mysql logo" />
        </div>
      </div>
    </div>
  );
};

export default Technology;
