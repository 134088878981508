import "./App.css";
import About from "./Components/About/About";
import Client from "./Components/Client/Client";
import Contact from "./Components/Contact/Contact";
import Customer from "./Components/Customer/Customer";
import Footer from "./Components/Footer/Footer";
import Header from "./Components/Header/Header";
import Hero from "./Components/Hero/Hero";
import Products from "./Components/Products/Products";
import Statistic from "./Components/Statistic/Statistic";
import Team from "./Components/Team/Team";
import CustomSoftware from "./Components/CustomSoftware/CustomSoftware";
import Technology from "./Components/Technology/Technology";

function App() {
  return (
    <div className="">
      <Header/>
      <Hero/>
      <About />
      <Products/>
      <CustomSoftware/> 
      <Technology/>
      <Client />
      <Team/>
      <Statistic />
      {/* <Customer/> */}
      <Contact/>
      <Footer/>
    </div>
  );
}

export default App;
