import React from "react";
import "./Team.scss";
import { MdFacebook } from "react-icons/md";
import { TiSocialLinkedinCircular } from "react-icons/ti";
import common from "../../image/team/common.png";
import ceo from "../../image/team/ceo.webp";
import madam from "../../image/team/madam.webp";

const Team = () => {
  return (
    <div className="about-margin">
      <div className="text-center mb-5">
        <h2 className="text-secondary mb-3">
          OUR AWESOME TEAM
        </h2>
      </div>
      <div className="d-md-flex justify-content-center px-3">
        <div className="team-card me-2 mb-3 mb-md-0">
          <div className="team-parent">
            <div className="team-top border rounded"></div>
            <div className="team-child">
              <img className="rounded-circle team-img" src={madam} alt="" />
            </div>
          </div>
          <div className="shadow team-bottom border py-4 px-3 text-center rounded">
            <div className="team-title pt-2 pb-1">
              <h6>Jobiada Khanom</h6>
              <p>Managing Director</p>
            </div>
            <div className="mb-3 d-flex align-items-center justify-content-center">
              <a
                href="https://www.facebook.com/najjtechnologies"
                target="blank"
                className="me-3 facebook-icon"
              >
                <MdFacebook />
              </a>
              <a
                href="https://www.linkedin.com/company/najj-technologies-limited/mycompany/"
                target="blank"
                className="linkind-icon"
              >
                <TiSocialLinkedinCircular />
              </a>
            </div>
            <div>
              <p>
              When something is important enough, you do it even if the odds are not in your favor.
              </p>
            </div>
          </div>
        </div>
        <div className="team-card me-2 mb-3 mb-md-0">
          <div className="team-parent">
            <div className="team-top border rounded"></div>
            <div className="team-child">
              <img className="rounded-circle team-img" src={ceo} alt="" />
            </div>
          </div>
          <div className="shadow team-bottom border py-4 px-3 text-center rounded">
            <div className="team-title pt-2 pb-1">
              <h6>A.M.A Ahmed</h6>
              <p>HoS</p>
            </div>
            <div className="mb-3 d-flex align-items-center justify-content-center">
              <a
                href="https://www.facebook.com/najjtechnologies"
                target="blank"
                className="me-3 facebook-icon"
              >
                <MdFacebook />
              </a>
              <a
                href="https://www.linkedin.com/company/najj-technologies-limited/mycompany/"
                target="blank"
                className="linkind-icon"
              >
                <TiSocialLinkedinCircular />
              </a>
            </div>
            <div>
              <p>
              Never give up, for that is just the place and time that the tide will turn.
              </p>
            </div>
          </div>
        </div>
        <div className="team-card me-2 mb-3 mb-md-0">
          <div className="team-parent">
            <div className="team-top border rounded"></div>
            <div className="team-child">
              <img className="rounded-circle team-img" src={common} alt="" />
            </div>
          </div>
          <div className="shadow team-bottom border py-4 px-3 text-center rounded">
            <div className="team-title pt-2 pb-1">
              <h6>Rashadul Islam</h6>
              <p>Software Engineer</p>
            </div>
            <div className="mb-3 d-flex align-items-center justify-content-center">
              <a
                href="https://www.facebook.com/najjtechnologies"
                target="blank"
                className="me-3 facebook-icon"
              >
                <MdFacebook />
              </a>
              <a
                href="https://www.linkedin.com/company/najj-technologies-limited/mycompany/"
                target="blank"
                className="linkind-icon"
              >
                <TiSocialLinkedinCircular />
              </a>
            </div>
            <div>
              <p>
              Change your life today. Don't gamble on the future, act now, without delay.
              </p>
            </div>
          </div>
        </div>
        <div className="team-card me-2 mb-3 mb-md-0">
          <div className="team-parent">
            <div className="team-top border rounded"></div>
            <div className="team-child">
              <img className="rounded-circle team-img" src={common} alt="" />
            </div>
          </div>
          <div className="shadow team-bottom border py-4 px-3 text-center rounded">
            <div className="team-title pt-2 pb-1">
              <h6>Md. Hasibul Hasan</h6>
              <p>Software Engineer</p>
            </div>
            <div className="mb-3 d-flex align-items-center justify-content-center">
              <a
                href="https://www.facebook.com/najjtechnologies"
                target="blank"
                className="me-3 facebook-icon"
              >
                <MdFacebook />
              </a>
              <a
                href="https://www.linkedin.com/company/najj-technologies-limited/mycompany/"
                target="blank"
                className="linkind-icon"
              >
                <TiSocialLinkedinCircular />
              </a>
            </div>
            <div>
              <p>
                Don't be pushed around by the fears in your mind. Be led by the
                dreams in your heart.
              </p>
            </div>
          </div>
        </div>
        <div className="team-card me-2 mb-3 mb-md-0">
          <div className="team-parent">
            <div className="team-top border rounded"></div>
            <div className="team-child">
              <img className="rounded-circle team-img" src={common} alt="" />
            </div>
          </div>
          <div className="shadow team-bottom border py-4 px-3 text-center rounded">
            <div className="team-title pt-2 pb-1">
              <h6>Joyanta Kumar Sarker</h6>
              <p>Software Engineer</p>
            </div>
            <div className="mb-3 d-flex align-items-center justify-content-center">
              <a
                href="https://www.facebook.com/najjtechnologies"
                target="blank"
                className="me-3 facebook-icon"
              >
                <MdFacebook />
              </a>
              <a
                href="https://www.linkedin.com/company/najj-technologies-limited/mycompany/"
                target="blank"
                className="linkind-icon"
              >
                <TiSocialLinkedinCircular />
              </a>
            </div>
            <div>
              <p>
              If you want to conquer fear, don't sit home and think about it. Go out and get busy.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
