import React from "react";
import { Col, Row } from "react-bootstrap";
import "./Footer.scss";
import logo from "../../image/NAJJ.png";
import { FaFacebookF, FaGooglePlusG, FaLinkedinIn } from "react-icons/fa";
import { FiYoutube } from "react-icons/fi";

const Footer = () => {
  let date = new Date().getFullYear();
  return (
    <div className="mt-5 bg-footer">
      <Row className="mx-5 pt-4 gy-2">
        <Col md={3} className="text-center">
          <img src={logo} alt="" className="footer-image"/>
        </Col>
        <Col md={2} className="border-line text-center">
          <div>
            <a className="nav-link active fw-bold" href="#home">
              HOME
            </a>
          </div>
          <div>
            <a className="nav-link active fw-bold" href="#about">
              ABOUT
            </a>
          </div>
        </Col>
        <Col md={2} className="border-line text-center">
          <div>
            <a className="nav-link active fw-bold" href="#products&services">
            PRODUCTS & SERVICES
            </a>
          </div>
          <div>
            <a className="nav-link active fw-bold" href="#contact">
              CONTACT
            </a>
          </div>
        </Col>
        <Col md={1}></Col>
        <Col md={4}>
          <div className="ms-md-3">
            <div className="d-flex">
              <a
                href="https://www.facebook.com/najjtechnologies"
                target="blank"
                className="text-decoration-none text-white btn w-50 facebook text-nowrap"
              >
                <FaFacebookF className="fs-3 me-1" /> Facebook
              </a>

              <a
                href="https://www.youtube.com/channel/UCA7meqQ7S0qFoWjxdbDscrQ"
                target="blank"
                className="text-decoration-none text-white btn w-50 ms-2 youtube text-nowrap"
              >
                <FiYoutube className="fs-3 me-1" /> YouTube
              </a>
            </div>
            <div className="d-flex mt-1">
              <a
                href="https://www.linkedin.com/company/najj-technologies-limited/mycompany/"
                target="blank"
                className="text-decoration-none text-white btn w-50 bg-info text-nowrap"
              >
                <FaLinkedinIn className="fs-3 me-2 me-md-1" /> Linkedin
              </a>
              <a
                href="/"
                target="blank"
                className="text-decoration-none text-white btn w-50 ms-2 google text-nowrap px-3 disabled"
              >
                <FaGooglePlusG className="fs-3 me-1" /> Google
              </a>
            </div>
          </div>
        </Col>
        <div className="text-center mt-4">
          <p>
            2021 - {date} Copyright © NAJJ Technologies Ltd. All rights
            reserved.
          </p>
        </div>
      </Row>
    </div>
  );
};

export default Footer;
