import React from "react";
import "./About.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Col, Row } from "react-bootstrap";
import about1 from "../../image/about/about1.svg";
import about2 from "../../image/about/about2.svg";
import about3 from "../../image/about/about3.svg";

const About = () => {
  let settings = {
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div id="about" className="container about-margin px-3">
      <Row className="d-md-flex align-items-center justify-content-center gy-5">
        <Col md={6}>
          <div className="about-img mx-auto">
          <Slider {...settings}>
              <div>
              <img className="rounded" src={about1} alt="aboutimage" />
              </div>
              <div>
              <img className="rounded" src={about2} alt="aboutimage" />
              </div>
              <div>
              <img className="rounded" src={about3} alt="aboutimage" />
              </div>
            </Slider>
          </div>
        </Col>
        <Col md={6}>
          <h2 className="text-secondary mb-3">
            ABOUT US
          </h2>
          <h5 className="about-text about-design-bottom">
            NAJJ Technologies Ltd. is a specialist software development company
            run by a dedicated and highly experienced IT management team, having
            all worked in technology and software solutions across globe. We
            combine this skill set with highly talented delivery specialists,
            ranging from project managers, business analysts to commercial
            software engineers. Based in Dhaka, Bangladesh we support clients
            throughout globe.
          </h5>
          <h5
            className="about-text about-design-left ps-4 py-3"
            style={{ fontFamily: "cursive" }}
          >
            "We value every partner we work with, their success is our success.
            We regularly review our performance and work with our partners to
            leverage, plan and achieve mutually beneficial outcomes "
          </h5>
        </Col>
      </Row>
    </div>
  );
};

export default About;
