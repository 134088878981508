import React from "react";
import { Col, Row } from "react-bootstrap";
import "./Products.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import sms from "../../image/product/sms.svg";
import sms2 from "../../image/product/sms2.svg";
import sms3 from "../../image/product/sms3.svg";
import tutory from "../../image/product/tutory.svg";
import tutory2 from "../../image/product/tutory2.svg";
import tutory3 from "../../image/product/tutory3.svg";
import protiva from "../../image/product/protiva.svg";
import protiva2 from "../../image/product/protiva2.svg";
import protiva3 from "../../image/product/protiva3.svg";
import Protivaa from "../../image/product/Protivaa.png";
import TutoryClub from "../../image/product/TutoryClub.png";
import SMSBangladesh from "../../image/product/SMSBangladesh.png";
import {
  FcCellPhone,
  FcOvertime,
  FcAutomotive,
  FcIphone,
  FcMoneyTransfer,
  FcFrame,
  FcOrgUnit,
  FcLink,
  FcDocument,
  FcDisplay,
  FcElectricalSensor,
} from "react-icons/fc";
import { FaSms } from "react-icons/fa";
import { MdSmsFailed, MdTextsms } from "react-icons/md";
import { ImGift } from "react-icons/im";

const Products = () => {
  let settings = {
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div id="products&services" className="about-margin container-fluid">
      <div className="text-center product-bottom">
        <h2 className="text-secondary mb-3">
          PRODUCTS & SERVICES
        </h2>
      </div>
      <Row className="gy-5 mt-5">
        <Col md={12} className="product-parent">
          <div className="p-2 image-div">
            <Slider {...settings}>
              <div>
                <img
                  className="product-image"
                  src={sms3}
                  alt="sms bangladesh"
                />
              </div>
              <div>
                <img
                  className="product-image"
                  src={sms2}
                  alt="sms bangladesh"
                />
              </div>
              <div>
                <img className="product-image" src={sms} alt="sms bangladesh" />
              </div>
            </Slider>
            <div className="product-logo">
              <img
                className="rounded-circle product-img p-2 bg-white shadow-lg"
                src={SMSBangladesh}
                alt="sms bangladesh"
              />
            </div>
          </div>
          <div className="bg-product-sms text-padding py-5">
            <div>
              <h2 className="sms-text-color">SMSBangladesh</h2>
              <p className="fs-5">
                An A2P SMS Aggregator Service Provider licensed by Bangladesh
                Telecommunication Regulatory Commission (BTRC).
              </p>
            </div>
            <div className="mt-5">
              <h5 className="sms-text-color">THE KEY STRENGTH</h5>
              <p>
                <li>
                  Direct connection with all Mobile operators in Bangladesh
                </li>
              </p>
              <p>
                <li>
                  Maintaining Priority and Fallback Route mechanism thus ensure
                  your SMS preference
                </li>
              </p>
              <p>
                <li>Web and API system for your sending flexibility</li>
              </p>
            </div>
            <div className="mt-5">
              <h5 className="sms-text-color">FEATURES</h5>
              <div className="d-md-flex">
                <div className="mt-2">
                  <p>
                    <MdTextsms className="fs-2 me-2 text-secondary" />
                    Masking / Non-Masking SMS
                  </p>
                  <p>
                    <MdSmsFailed className="fs-2 me-2 text-success" />
                    Transactional Alerts
                  </p>
                </div>
                <div className="ms-md-5 mt-2">
                  <p>
                    <FcCellPhone className="fs-2 me-2" />
                    User Verification (OTP)
                  </p>
                  <p>
                    <FaSms className="fs-2 me-2 text-info" />
                    Promotional SMS
                  </p>
                </div>
              </div>
              <div className="text-center mt-5">
                <a
                  href="http://smsbangladesh.com/"
                  target="blank"
                  className="btn px-4 bg-btn-sms border-0 text-white"
                >
                  Visit Website
                </a>
              </div>
            </div>
          </div>
        </Col>
        <Col md={12} className="product-2nd-parent">
          <div className="bg-product-tutory text-padding py-5 tutory-div">
            <div>
              <h2 className="tutor-text-color">Tutory Club</h2>
              <p className="fs-5 pe-3">
                An Online Tutoring Platform design for Tutors and Students. You
                can do a TEST Class before enroll your course with any
                tutors.This is a BEST initiatives for you to make you happy!
              </p>
            </div>
            <div className="mt-5">
              <h5 className="tutor-text-color">THE KEY STRENGTH</h5>
              <p>
                <li>
                  All-in-One Platform for Online Tutoring (Live Class, Homework,
                  Messaging)
                </li>
              </p>
              <p>
                <li>
                  Specialist and Experienced Tutors from renowned Institutions
                  Nationwide
                </li>
              </p>
              <p>
                <li>
                  Hassle-Free Online Payment System for both Students and Tutors
                </li>
              </p>
            </div>
            <div className="mt-5">
              <h5 className="tutor-text-color">FEATURES</h5>
              <div className="d-md-flex">
                <div className="mt-2">
                  <p>
                    <FcOvertime className="fs-2 me-2" />
                    Flexible Time
                  </p>
                  <p>
                    <FcIphone className="fs-2 me-2" />
                    Accessibility and Mobility
                  </p>
                </div>
                <div className="ms-md-5 mt-2">
                  <p>
                    <FcAutomotive className="fs-2 me-2" />
                    No traveling, no time waste
                  </p>
                  <p>
                    <FcMoneyTransfer className="fs-2 me-2" />
                    Pay per Lesson
                  </p>
                </div>
              </div>
              <div className="text-center mt-5">
                <a
                  href="http://tutory.club/"
                  target="blank"
                  className="btn px-4 bg-btn-tutory border-0 text-white"
                >
                  Visit Website
                </a>
              </div>
            </div>
            <div className="tutory-logo">
              <img
                className="rounded-circle product-img-tutory shadow-lg p-2 bg-tutory-logo"
                src={TutoryClub}
                alt="sms bangladesh"
              />
            </div>
          </div>
          <div className="p-2 image-div">
            <Slider {...settings}>
              <div>
                <img className="product-image" src={tutory} alt="tutory club" />
              </div>
              <div>
                <img
                  className="product-image"
                  src={tutory2}
                  alt="tutory club"
                />
              </div>
              <div>
                <img
                  className="product-image"
                  src={tutory3}
                  alt="tutory club"
                />
              </div>
            </Slider>
          </div>
        </Col>
        <Col md={12} className="product-parent protiva-res-margin">
          <div className="p-2 image-div">
            <Slider {...settings}>
              <div>
                <img className="product-image" src={protiva} alt="protiva" />
              </div>
              <div>
                <img className="product-image" src={protiva2} alt="protiva" />
              </div>
              <div>
                <img className="product-image" src={protiva3} alt="protiva" />
              </div>
            </Slider>
            <div className="protiva-logo">
              <img
                className="rounded-circle product-img-protiva p-2 bg-protiva-logo shadow-lg"
                src={Protivaa}
                alt="protiva"
              />
            </div>
          </div>
          <div className="bg-product-protiva text-padding py-5">
            <div>
              <h2 className="protiva-text-color">Protivaa - প্রতিভা</h2>
              <p className="fs-5 pe-md-4">
                একটি অনলাইন কুইজ প্ল্যাটফর্ম, এখানে ছোট বড় সকলের জন্য উপযোগী
                একটি প্রচেষ্টা যেখানে বুদ্ধিবৃদ্ধির সাথে বিভিন্ন পুরস্কার জেতার
                সম্ভাবনা রয়েছে। মোবাইল কিংবা কম্পিউটারের মাধ্যমে যেকোনো সময়
                যেকোনো জায়গা থেকে খেলা যায়, তাই সময় কাটানোর পাশাপাশি নলেজ
                বৃদ্ধি পাবে, যা যেকোনো প্রতিযোগিতা মূলক কাজে সহায়ক হবে।
              </p>
            </div>
            <div className="mt-5">
              <h5 className="protiva-text-color">পুরস্কার</h5>
              <div className="d-md-flex mt-4 align-items-center">
                <p className="me-4 d-flex align-items-center">
                  <ImGift className="fs-2 me-2 text-danger" />
                  <span className="pt-2">দৈনিক পুরস্কার</span>
                </p>
                <p className="me-4 d-flex align-items-center">
                  <ImGift className="fs-2 me-2 text-danger" />
                  <span className="pt-2">সাপ্তাহিক পুরস্কার</span>
                </p>
                <p className="me-4 d-flex align-items-center">
                  <ImGift className="fs-2 me-2 text-danger" />
                  <span className="pt-2">মাসিক পুরস্কার</span>
                </p>
              </div>
            </div>
            <div className="mt-4">
              <h5 className="protiva-text-color">কুইজ </h5>
              <div className="d-flex mt-3">
                <div className="me-md-5">
                  <p>
                    <FcDisplay className="fs-2 me-2" />
                    এমসিকিউ
                  </p>
                  <p>
                    <FcLink className="fs-2 me-2" />
                    সত্য-মিথ্যা
                  </p>
                  <p>
                    <FcFrame className="fs-2 me-2" />
                    পাজল ছবি
                  </p>
                </div>
                <div className="ms-5">
                  <p>
                    <FcElectricalSensor className="fs-2 me-2" />
                    এমএসকিউ
                  </p>
                  <p>
                    <FcDocument className="fs-2 me-2" />
                    লেখা পাজল
                  </p>
                  <p>
                    <FcOrgUnit className="fs-2 me-2" />
                    পাজল গেইম
                  </p>
                </div>
              </div>
              <div className="text-center mt-5">
                <a
                  href="https://protivaa.com/"
                  target="blank"
                  className="btn px-4 bg-btn-protiva border-0 text-white"
                >
                  Visit Website
                </a>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Products;
