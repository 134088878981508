import React from "react";
import { Col, Row } from "react-bootstrap";
import "./Statistic.scss";

const Statistic = () => {
  return (
    <div className="statistic-margin container">
      <div className="text-center mb-5">
        <h2 className="text-secondary mb-3">OUR PROGRESS</h2>
      </div>
      <Row className="gy-4">
        <Col md={3}>
          <div className="shadow-lg text-center p-3 rounded text-secondary view-button">
            <h1>500+</h1>
            <h3>CLIENTS</h3>
          </div>
        </Col>
        <Col md={3}>
          <div className="shadow-lg text-center p-3 rounded text-secondary view-button">
            <h1>15+</h1>
            <h3>EMPLOYEES</h3>
          </div>
        </Col>
        <Col md={3}>
          <div className="shadow-lg text-center p-3 rounded text-secondary view-button">
            <h1>5</h1>
            <h3>OPERATORS</h3>
          </div>
        </Col>
        <Col md={3}>
          <div className="shadow-lg text-center p-3 rounded text-secondary view-button">
            <h1>20+</h1>
            <h3>BRAND</h3>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Statistic;
