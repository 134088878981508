import React from "react";
import image3 from "../../image/3.jpg";
import "./Hero.scss";
import LightSpeed from "react-reveal/LightSpeed";

const Hero = () => {
  return (
    <div
      id="home"
      className="bg-image mt-5"
      style={{
        backgroundImage: `url(${image3})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        overflowX: "hidden"
      }}
    >
      <LightSpeed top>
        <div className="px-5">
          <h1 className="fw-bold title animate-charcter">
            NAJJ Technologies Limited
          </h1>
          <h5 className="title-bottom">
            Need a software development partner? We can help you make an impact.
          </h5>
        </div>
      </LightSpeed>
    </div>
  );
};

export default Hero;
