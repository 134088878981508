import React from "react";
import "./Header.scss";
import logo from "../../image/NAJJ.png";

const Header = () => {

  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-light bg-light text-white fixed-top">
        <div className="container">
          <a className="navbar-brand navbar-icons" href="#home">
            <img className="najj-logo" src={logo} alt="Najj Tech" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav m-auto mb-2 mb-lg-0">
              <a className="navbar-brand navbar-second-icon" href="#home">
                <img className="najj-logo" src={logo} alt="Najj Tech" />
              </a>
              <li className="nav-item mt-md-2">
                <a className="nav-link active fw-bold one" href="#home">
                  <span className="headerText">HOME</span>
                </a>
              </li>
              <li className="nav-item mt-md-2">
                <a className="nav-link active fw-bold two" href="#about">
                  {" "}
                  <span className="headerText">ABOUT</span>
                </a>
              </li>
              <li className="nav-item mt-md-2">
                <a className="nav-link active fw-bold three" href="#products&services">
                  {" "}
                  <span className="headerText">PRODUCTS & SERVICES</span>
                </a>
              </li>
              <li className="nav-item mt-md-2">
                <a className="nav-link active fw-bold four" href="#contact">
                  {" "}
                  <span className="headerText">CONTACT</span>
                </a>
              </li>
              <hr />
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
