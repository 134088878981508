import React from "react";
import "./Client.scss";
import grameenphone from "../../image/client/grameenphone.png";
import banglalink from "../../image/client/banglalink.webp";
import airtel from "../../image/client/airtel.webp";
import robi from "../../image/client/robi.webp";
import teletalk from "../../image/client/teletalk.webp";

const Client = () => {
  return (
    <div className="d-md-flex container contact-margin align-items-center">
      <div className="d-flex align-items-center justify-content-center mb-5 mb-md-0 tech-div">
        <h2 className="text-secondary">
          TELECOM <br className="d-none d-md-block" /> PARTNERS
        </h2>
      </div>
      <div className="technology d-none d-md-block" />
      <div className="ms-md-4 d-md-flex align-items-center justify-content-between w-100">
        <div className="d-flex align-items-center justify-content-center mb-5 mb-md-0">
          <img
            className="client-img grameenphone"
            src={grameenphone}
            alt="Grameenphone logo"
          />
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <img className="banglalink" src={banglalink} alt="banglalink logo" />
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <img className="robi" src={robi} alt="robi logo" />
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <img className="airtel" src={airtel} alt="airtel logo" />
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <img className="teletalk" src={teletalk} alt="teletalk logo" />
        </div>
      </div>
    </div>
  );
};

export default Client;
