import React from "react";
import "./CustomSoftware.scss";
import { Col, Row } from "react-bootstrap";
import logo from "../../image/custom/logo.svg";
import custom from "../../image/custom/custom.svg";

import { FcCheckmark } from "react-icons/fc";

const CustomSoftware = () => {
  return (
    <div className="container-fluid custom-parent">
      <Row className="d-md-flex align-items-center justify-content-center gy-5 custom-bg pb-3">
        <Col md={6}>
          <div className="text-center">
            <div>
              <img
                className="rounded custom-soft-img"
                src={custom}
                alt="aboutimage"
              />
            </div>
          </div>
        </Col>
        <Col md={6}>
          <h2 className="text-secondary mb-3">
            Custom Software Development
          </h2>
          <h5 className="about-text">
            We at NAJJ Tech, offer end-to-end software development and mobile
            apps development services, by leveraging our strong domain expertise
            and skills in pioneering technologies.
          </h5>
          <div className="mt-3">
            <div className="d-flex align-items-center">
              <FcCheckmark className="fs-4" />
              <h6 className="mt-2 ms-1">SAAS Applications</h6>
            </div>
            <div className="d-flex align-items-center">
              <FcCheckmark className="fs-4" />
              <h6 className="mt-2 ms-1">API Development</h6>
            </div>
            <div className="d-flex align-items-center">
              <FcCheckmark className="fs-4" />
              <h6 className="mt-2 ms-1">New Product Development</h6>
            </div>
            <div className="d-flex align-items-center">
              <FcCheckmark className="fs-4" />
              <h6 className="mt-2 ms-1">Advanced Web Applications</h6>
            </div>
            <div className="d-flex align-items-center">
              <FcCheckmark className="fs-4" />
              <h6 className="mt-2 ms-1">Digital Content Management System</h6>
            </div>
            <div className="d-flex align-items-center">
              <FcCheckmark className="fs-4" />
              <h6 className="mt-2 ms-1">Application Maintenance and Support</h6>
            </div>
          </div>
        </Col>
      </Row>
      <div className="custom-logo">
        <div className="res-custom-image">
          <img className="custom-image" src={logo} alt="" />
          <div className="custom-text text-center text1">
            <small>
              You tell us which business goals your new software should enable.
            </small>
          </div>
          <div className="custom-text text-center text2">
            <strong>IDEA</strong>
          </div>
          <div className="custom-text text-center text3">
            <strong>BUSINESS ANALYSIS</strong>
          </div>
          <div className="custom-text text-center text4">
            <small>
              Together, we determine how it should relate to current processes.
            </small>
          </div>
          <div className="custom-text text-center text5">
            <small>
              Our team ideates how the software will look and function.
            </small>
          </div>
          <div className="custom-text text-center text6">
            <strong>UI DESIGN</strong>
          </div>
          <div className="custom-text text-center text7">
            <strong>DEVELOPMENT</strong>
          </div>
          <div className="custom-text text-center text8">
            <small>
              We build the solution step by step, with ongoing quality
              assurance.
            </small>
          </div>
          <div className="custom-text text-center text9">
            <small>
              We determine together that the software works as it should.
            </small>
          </div>
          <div className="custom-text text-center text10">
            <strong>TESTING</strong>
          </div>
          <div className="custom-text text-center text11">
            <strong>DEPLOYMENT</strong>
          </div>
          <div className="custom-text text-center text12">
            <small>
              We work with your team to launch the system for your organization.
            </small>
          </div>
          <div className="custom-text text-center text13">
            <small>
              Our team continues to monitor the solution in an ongoing capacity.
            </small>
          </div>
          <div className="custom-text text-center text14">
            <strong>MAINTENANCE AND SUPPORT</strong>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomSoftware;
